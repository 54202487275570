/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-08-16",
    versionChannel: "nightly",
    buildDate: "2023-08-16T00:25:12.644Z",
    commitHash: "b1981debc94b1dbe9378a53dae805206af9486da",
};
